import { styled } from '@mui/material/styles'

const RootBox = styled('main')(({ theme }) => ({
  overflowY: 'hidden',
  display: 'grid',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  [theme.breakpoints.only('md')]: {
    gridTemplateColumns: '1fr 2fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '4fr 10fr',
  },
}))

export default RootBox
