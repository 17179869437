import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField, Typography } from '@mui/material'

import { SegmentCard } from './components'

import { useRegistration, useJourney } from 'hooks'

import schema from './schema'
import constants from 'constants/index'

const Segment = () => {
  const {
    company,
    updateCompany,
    setCompanyChanged,
    anotherSegment,
    setAnotherSegment,
  } = useRegistration()
  const { handleNext } = useJourney()

  const [segmentId, setSegmentId] = useState<number | 'another' | undefined>(
    company?.segmentId || undefined
  )

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      segmentId: company?.segmentId?.toString() || undefined,
    },
  })

  const onSubmit = () => {
    if (company?.segmentId !== segmentId) {
      if (segmentId === 'another') {
        updateCompany({ segmentId: undefined })
      } else {
        updateCompany({ segmentId: Number(segmentId) })
      }
      setCompanyChanged(true)
    }

    handleNext()
  }

  const setExistentSegment = (newSegmentId: string) => {
    const newSegment =
      newSegmentId !== 'another' ? Number(newSegmentId) : newSegmentId
    setSegmentId(newSegment)
    setValue('segmentId', newSegmentId)
    setAnotherSegment(undefined)
  }

  useEffect(() => {
    if (anotherSegment?.id && anotherSegment?.id !== segmentId) {
      setSegmentId(Number(anotherSegment?.id))
      setValue('segmentId', anotherSegment?.id?.toString())
    }

    //eslint-disable-next-line
  }, [anotherSegment, segmentId])

  return (
    <>
      <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" color="primary">
              Segmento
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="h6"
              fontWeight="400"
              {...{ color: errors.segmentId ? 'red !important' : 'primary' }}
            >
              Selecione o segmento da sua organização
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {constants.segments?.SEGMENT_LIST.map((segment) => (
                <SegmentCard
                  key={segment.id}
                  active={segmentId === segment.id}
                  setSegment={setExistentSegment}
                  segment={{
                    id: segment.id,
                    name: segment.name,
                    description: segment.description,
                  }}
                  Icon={segment.Icon}
                />
              ))}
            </Grid>
          </Grid>
          <Controller
            render={({ field }) => (
              <TextField {...field} value={segmentId} type="hidden" />
            )}
            name="segmentId"
            control={control}
          />
        </Grid>
      </form>
    </>
  )
}

export default Segment
