import { Button as ButtonMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type ButtonType = {
  $alignItems?: Property.AlignItems
  $backgroundColor?: Property.BackgroundColor
  $border?: Property.Border<TLength>
  $borderColor?: Property.BorderColor
  $borderRadius?: Property.BorderRadius<TLength>
  $boxShadow?: Property.BoxShadow
  $color?: Property.Color
  $display?: Property.Display
  $fontFamily?: Property.FontFamily
  $fontSize?: Property.FontSize<TLength>
  $height?: Property.Height<TLength>
  $justifyContent?: Property.JustifyContent
  $opacity?: Property.Opacity
  $p?: Property.Padding<TLength>
  $pl?: Property.PaddingLeft<TLength>
  $px?: Property.PaddingInline<TLength>
  $py?: Property.PaddingBlock<TLength>
  $textTransform?: Property.TextTransform
  $width?: Property.Width<TLength>
}

interface ButtonWithValidationsType extends ButtonType {
  $hover?: InferType<ButtonType>
  $smDown?: InferType<ButtonType>
}

const Button = styled(ButtonMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<ButtonWithValidationsType>(
  ({
    theme,
    $alignItems,
    $backgroundColor,
    $border,
    $borderColor,
    $borderRadius,
    $boxShadow,
    $color,
    $display,
    $fontFamily,
    $fontSize,
    $height,
    $hover,
    $justifyContent,
    $opacity,
    $p,
    $pl,
    $px,
    $py,
    $textTransform,
    $width,
    $smDown,
  }) => ({
    alignItems: $alignItems,
    backgroundColor: $backgroundColor,
    border: $border,
    borderColor: $borderColor,
    borderRadius: $borderRadius,
    boxShadow: $boxShadow,
    color: $color,
    display: $display,
    fontFamily: $fontFamily,
    height: $height,
    justifyContent: $justifyContent,
    opacity: $opacity,
    padding: $p && theme.spacing($p),
    paddingBlock: $py && theme.spacing($py),
    paddingInline: $px && theme.spacing($px),
    paddingLeft: $pl && theme.spacing($pl),
    textTransform: $textTransform,
    width: $width,
    fontSize: $fontSize,
    '&:hover': {
      backgroundColor: $hover?.backgroundColor,
      borderColor: $hover?.borderColor,
      opacity: $hover?.opacity,
      boxShadow: $hover?.boxShadow,
    },
    [theme.breakpoints.down('sm')]: {
      width: $smDown?.width,
    },
  })
)

export default Button
