import { Box, Grid, Typography } from '@mui/material'
import { Icon } from 'react-feather'
import { theme } from 'theme'

import { SegmentType } from 'types/segment.types'

const SegmentCard = ({
  segment,
  Icon,
  active,
  setSegment,
  onClick,
}: {
  segment: SegmentType
  Icon: Icon
  active?: boolean
  setSegment: (segment: string) => void
  onClick?: VoidFunction
}) => {
  const handleActive = () => {
    setSegment(segment.id?.toString())
  }

  return (
    <Grid item lg={4} md={6} xs={12}>
      <Box
        sx={{
          cursor: 'pointer',
          ...(active
            ? {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.common.white,
              }
            : {
                border: `solid 1px ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
              }),
          borderRadius: '4px',
        }}
        onClick={onClick || handleActive}
        minHeight={124}
        px={4}
        py={2}
        display="flex"
        alignItems="center"
      >
        <Box mr={6}>
          <Icon size={31} />
        </Box>
        <Box
          minHeight={80}
          display="flex"
          flexDirection="column"
          alignContent="space-between"
          sx={{
            width: 'calc(100% - 70px)',
          }}
        >
          <Typography
            sx={{
              fontSize: '2rem !important',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="h6"
            gutterBottom
          >
            {segment?.name}
          </Typography>
          <Typography variant="subtitle1">{segment?.description}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default SegmentCard
