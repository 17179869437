import { useEffect, useState } from 'react'

import { Grid, Typography } from '@mui/material'

import { MUI } from 'components'
import { CardPlan } from 'views/WelcomeSteps/components'
import { useJourney } from 'hooks'

import constants from 'constants/index'

import leaveningLogo from 'images/platforms/leavening.png'
import adoptLogo from 'images/platforms/adopt.png'

const Banner = () => {
  const { setNextDisabled } = useJourney()

  const [bannerPlan, setBannerPlan] = useState<number>()

  const handleBannerPlan = (plan: number) => {
    setNextDisabled(false)
    setBannerPlan(plan)
  }

  useEffect(() => {
    if (!bannerPlan) {
      setNextDisabled(true)
    }

    return () => setNextDisabled(false)
    //eslint-disable-next-line
  }, [])

  return (
    <Grid container spacing={6} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Banner de Cookies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Escolha a opção que melhor se enquadra a sua empresa
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xs={12} onClick={() => handleBannerPlan(0)}>
            <CardPlan
              title="Já tenho banner de cookies"
              subtitle="Não ativar"
              observation="Caso mude de ideia, você poderá adquirir um banner futuramente"
              advantages={[]}
              isCentered
              selected={bannerPlan === 0}
              fullHeight
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} onClick={() => handleBannerPlan(1)}>
            <CardPlan
              title={
                <MUI.Img src={leaveningLogo} alt="adopt-logo" height="66px" />
              }
              advantages={constants.banner.LEAVENING_ADVANTAGES}
              isCentered
              selected={bannerPlan === 1}
              fullHeight
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} onClick={() => handleBannerPlan(2)}>
            <CardPlan
              title={<MUI.Img src={adoptLogo} alt="adopt-logo" height="63px" />}
              subtitle="R$62,00"
              advantages={constants.banner.ADOPT_ADVANTAGES}
              isCentered
              selected={bannerPlan === 2}
              fullHeight
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Banner
