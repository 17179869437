import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Box, Button } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import {
  CompanyLogo,
  BannerStep,
  CookiesStep,
  PlanStep,
  PoliciesStep,
  SegmentStep,
  StampStep,
  WelcomeStep,
} from './steps'
import { LoadingTrails } from 'components'

import { formatters } from 'helpers'

import { useFetch, useJourney, useMediaQuery, useRegistration } from 'hooks'

import constants from 'constants/index'

import service from 'service'
import routes from 'constants/routes'

const WelcomeSteps = () => {
  const navigate = useNavigate()
  const { up } = useMediaQuery()
  const {
    activeStep,
    backDisabled,
    nextDisabled,
    stepInfo,
    steps,
    handleBack,
    handleNext,
    setSteps,
    setStepInfo,
  } = useJourney()
  const { loaded, company, loadData } = useRegistration()

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      return navigate(routes.root)
    }

    const companyToken =
      company?.token ?? service.dponet.auth.getRegistrationCompanyToken()
    if (!company?.token && !!companyToken) {
      loadData()
    }
    //eslint-disable-next-line
  }, [])

  const isFinalStep = activeStep === steps.length - 1

  const { response } = useFetch(
    service.dponet.company.consultQRCode,
    {
      token: company?.token,
    },
    [company],
    !!company?.token
  )

  const qrCode = formatters.baseURL(response?.data?.object)

  useEffect(() => {
    if (isEmpty(steps)) {
      setSteps(constants.welcomeSteps.STEPS)
    }
    setStepInfo(constants.welcomeSteps.STEPS[activeStep])
    //eslint-disable-next-line
  }, [activeStep])

  const COMPONENTS_STEPS = [
    <WelcomeStep />,
    <SegmentStep />,
    <CookiesStep />,
    <BannerStep />,
    <PoliciesStep />,
    <StampStep qrCode={qrCode} />,
    <CompanyLogo />,
    <PlanStep />,
  ]

  const handleNextStep = () => {
    if (!stepInfo?.isForm) handleNext()
  }

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <LoadingTrails open={!loaded} />
      {!!loaded && COMPONENTS_STEPS[activeStep]}
      <Box
        py={4}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          {...(up.md
            ? {
                justifyContent: 'end',
                width: '100%',
              }
            : {
                pt: 2,
              })}
        >
          {activeStep > 0 && activeStep < steps.length - 1 && (
            <Box mr={2}>
              <Button
                disabled={backDisabled}
                variant="outlined"
                onClick={handleBack}
              >
                Voltar
              </Button>
            </Box>
          )}
          <Button
            {...(stepInfo?.isForm && {
              key: 'button-submit-form',
              type: 'submit',
              form: 'form-register',
            })}
            variant="contained"
            disabled={nextDisabled}
            onClick={handleNextStep}
          >
            {isFinalStep ? 'Finalizar' : 'Avançar'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default WelcomeSteps
