import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const create = async (data: object) => {
  return await dponetAPI.post('/pre_registrations', data)
}

const show = async () => {
  return await dponetAPI.get('/pre_registrations/show')
}

export default { create, show }
