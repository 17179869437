import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { AuthLayout, JourneyLayout, MainLayout } from './layouts'
import {
  Auth,
  Banner,
  Checkout,
  Consent,
  Error,
  Home,
  Marketplace,
  Policies,
  ServiceChannel,
  Tickets,
  WelcomeSteps,
  PrivacyChannelConfiguration,
  Settings,
} from 'views'

import {
  AuthProvider,
  PrivacyChannelProvider,
  RegistrationProvider,
} from 'providers'

import routes from 'constants/routes'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<JourneyLayout />}>
          <Route
            path={routes.auth.register}
            element={
              <RegistrationProvider>
                <WelcomeSteps />
              </RegistrationProvider>
            }
          />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path={routes.auth.login} element={<Auth.Login />} />
          <Route
            path={routes.auth.recoveryPassword}
            element={<Auth.Recovery />}
          />
          <Route
            path={routes.auth.resetPassword}
            element={<Auth.NewPassword />}
          />
        </Route>
        <Route element={<AuthProvider />}>
          <Route
            element={<JourneyLayout journey="privacyChannelConfiguration" />}
          >
            <Route
              path={routes.privacyChannel.configuration}
              element={
                <PrivacyChannelProvider>
                  <PrivacyChannelConfiguration />
                </PrivacyChannelProvider>
              }
            />
          </Route>
          <Route element={<MainLayout />}>
            <Route path={routes.root} element={<Home.Main />} />
            <Route
              path={routes.maturityQuestionnaire}
              element={<Home.MaturityQuestionnaire />}
            />
            <Route
              path={routes.serviceChannel.automaticAnswer}
              element={<ServiceChannel.AutomaticAnswer />}
            />
            <Route
              path={routes.serviceChannel.stamps}
              element={<ServiceChannel.Stamps />}
            />
            <Route path={routes.tickets.all} element={<Tickets.Main />} />
            <Route path={routes.tickets.show} element={<Tickets.Content />} />

            <Route path={routes.banners.all} element={<Banner.Main />} />
            <Route
              path={routes.banners.settings}
              element={<Banner.Configuration />}
            />
            <Route path={routes.consents.all} element={<Consent.Main />} />

            <Route path={routes.policies.all} element={<Policies.Main />} />
            <Route path={routes.policies.new} element={<Policies.Manage />} />
            <Route path={routes.policies.edit} element={<Policies.Manage />} />

            <Route
              path={routes.marketplace.all}
              element={<Marketplace.Main />}
            />
            <Route
              path={routes.marketplace.service}
              element={<Marketplace.Show />}
            />
            <Route
              path={routes.marketplace.products}
              element={<Marketplace.Products />}
            />
            <Route
              path={routes.marketplace.productShow}
              element={<Marketplace.ProductShow />}
            />
            <Route
              path={routes.marketplace.orders}
              element={<Marketplace.Orders />}
            />

            <Route path={routes.settings.all} element={<Settings.Main />} />
          </Route>

          <Route path={routes.checkout.all} element={<Checkout.Main />} />
        </Route>
        <Route path={routes.notFound} element={<Error.NotFound />} />
        <Route path={routes.forbidden} element={<Error.Forbidden />} />
        <Route path={routes.internalError} element={<Error.InternalError />} />
        <Route path={routes.offline} element={<Error.Offline />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
