/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup'

import { formatters, validates } from 'helpers'

import constants from 'constants/index'

const allowedTypes = constants.settings.SUPPORTED_IMAGE_FILE_INPUT_TYPES

const schema = yup.object({
  name: yup.string().min(3).max(255).required(),
  description: yup.string().max(500).required(),
  email: yup.string().email().min(4).max(254).required(),
  phone: yup.string().required().min(14).max(15),
  siteUrl: yup.string().url().max(255),
  logo: yup
    .mixed()
    .test('fileSize', 'A imagem deve ter no máximo 5 MB', (value: any) =>
      validates.file.size('MB', 5, value)
    )
    .test(
      'fileType',
      `Somente arquivos ${formatters.file.allowedTypes(allowedTypes)} são permitidos`,
      (value: any) =>
        validates.file.type(
          constants.settings.SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
          value
        )
    ),
})

export default schema
