import { Controller } from 'react-hook-form'
import { FormLabel, TextField as TextFieldMUI, Typography } from '@mui/material'

import { TextFieldType } from './textField.types'

const TextField = ({
  control,
  errors,
  name,
  label,
  type = 'text',
}: TextFieldType) => {
  const hasError = !!errors?.[name]

  return (
    <>
      <FormLabel title={label} error={hasError}>
        <Typography fontWeight="400" color={hasError ? 'red' : 'primary'}>
          {label}
        </Typography>
      </FormLabel>
      <Controller
        render={({ field }) => (
          <TextFieldMUI
            {...field}
            error={hasError}
            helperText={<>{errors?.[name]?.message}</>}
            type={type}
            fullWidth
          />
        )}
        name={name}
        control={control}
      />
    </>
  )
}

export default TextField
