import { Check } from 'react-feather'
import { Box, Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { CardPlanType } from './cardPlan.types'
import { CardWithValidationsType } from 'components/MUI/Card/Card'

import { theme, themeJourney } from 'theme'

const CardPlan = ({
  title,
  subtitle,
  advantages,
  isCentered,
  observation,
  selected,
  fullHeight,
  ...rest
}: CardPlanType & CardWithValidationsType) => {
  return (
    <MUI.Card
      variant="outlined"
      $border={`solid 2px ${selected ? theme.palette.primary.main : theme.palette.paper.border}`}
      $height={fullHeight ? '100%' : 'fit-content'}
      {...rest}
    >
      <MUI.CardActionArea $height={fullHeight ? '100%' : 'fit-content'}>
        <Box p={8} height={fullHeight ? '100%' : 'fit-content'}>
          <Box
            py={4}
            display="flex"
            justifyContent="center"
            {...(isCentered && {
              alignItems: 'center',
              gap: 6,
            })}
            flexDirection="column"
          >
            <Box display="flex" maxHeight={60}>
              <Typography
                textAlign={isCentered ? 'center' : 'start'}
                variant="h6"
                fontWeight="700"
              >
                {title}
              </Typography>
            </Box>
            <Typography
              color={themeJourney.palette.secondary.light}
              variant="h5"
              fontWeight="700"
            >
              {subtitle ?? 'GRÁTIS'}
            </Typography>
          </Box>

          <Box py={2}>
            <Grid container spacing={2}>
              {!!observation && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" textAlign="justify">
                    {observation}
                  </Typography>
                </Grid>
              )}
              {advantages?.map((advantage) => (
                <Grid key={advantage?.name} item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <Check color="#3BB7103C" size={22} />
                    </Box>
                    <Typography variant="subtitle1">
                      {advantage?.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </MUI.CardActionArea>
    </MUI.Card>
  )
}

export default CardPlan
