import moment from 'moment'

moment.locale('pt-br')

type dateType = number | string | null

const ptBrFormatDate = (date?: dateType, response?: string) => {
  return date ? moment(date).format('DD/MM/YYYY') : response ?? '-'
}

const ptBrFormatHours = (date?: dateType, response?: string) => {
  return date ? moment(date).format('HH:mm') : response ?? '-'
}

const ptBrFormatDateTime = (date?: dateType, response?: string) => {
  return date ? moment(date).format('DD/MM/YYYY [às] HH:mm') : response ?? '-'
}

const currentYear = () => moment().format('YYYY')

export default {
  ptBrFormatDate,
  ptBrFormatHours,
  ptBrFormatDateTime,
  currentYear,
}
