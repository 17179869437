import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTrait = api.create('dponet', false)

const get = async () => await dponetAPI.get('/company_maturity')

const reply = async (data: object) =>
  await dponetAPINotTrait.put('/company_maturity/reply', data)

export default {
  get,
  reply,
}
