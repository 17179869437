import {
  CheckCircle as CheckCircleIcon,
  AlertTriangle as AlertFailedIcon,
  Loader as LoaderIcon,
} from 'react-feather'

import {
  BannerPreview,
  ColorCustomization,
  DescriptionCustomization,
  LogoPreview,
  LogoUpload,
  PositionCustomization,
  PositionPreview,
} from 'views/Banner/components/BannerForm/components/BannerCustomizationCard/components'
import { NewBannerModalControlType } from 'views/Banner/components/NewBannerModal/schema'

import { ObjectOf } from 'types/global.types'
import { ChipColorType } from 'types/materialUI.types'
import { BannerConfigurationCardItemsType } from 'types/banner.types'

import { theme } from 'theme'
import routes from './routes'

// BANNER CUSTOMIZATION CARD
const COLOR_CUSTOMIZATION_CARD_ID = 1
const LOGO_CUSTOMIZATION_CARD_ID = 2
const DESCRIPTION_CUSTOMIZATION_CARD_ID = 3
const POSITION_CUSTOMIZATION_CARD_ID = 4

// BANNER PLAN
const NO_BANNER_PLAN_ID = 0
const LEAVENING_BANNER_PLAN_ID = 1
const ADOPT_BANNER_PLAN_ID = 2

// BANNER STATUS
const ACTIVE_STATUS_ID = 'active'
const INCOMPLETE_STATUS_ID = 'unconfigured'
const INACTIVE_STATUS_ID = 'inactive'

// SCANNER LOGS STATUS
const COMPLETED_SCANNER_STATUS_ID = 'succeded'
const INITIATED_SCANNER_STATUS_ID = 'initiated'
const FAILED_SCANNER_STATUS_ID = 'failed'

// BANNER CUSTOMIZATION POSITION
const BANNER_LEFT_POSITION = 'left'
const BANNER_RIGHT_POSITION = 'right'
const BANNER_CENTER_POSITION = 'center'
const BANNER_FIXED_POSITION = 'fixed'

const CUSTOMIZATION_CARD_IDS = [
  COLOR_CUSTOMIZATION_CARD_ID,
  LOGO_CUSTOMIZATION_CARD_ID,
  DESCRIPTION_CUSTOMIZATION_CARD_ID,
] as const

const STATUSES_IDS = [
  ACTIVE_STATUS_ID,
  INCOMPLETE_STATUS_ID,
  INACTIVE_STATUS_ID,
] as const

const SCANNER_STATUSES_IDS = [
  COMPLETED_SCANNER_STATUS_ID,
  FAILED_SCANNER_STATUS_ID,
  INITIATED_SCANNER_STATUS_ID,
] as const

const BANNER_POSITION_IDS = [
  BANNER_LEFT_POSITION,
  BANNER_RIGHT_POSITION,
  BANNER_CENTER_POSITION,
  BANNER_FIXED_POSITION,
] as const

const CONFIGURATION_PAGE_HEADER_CATEGORIES = [
  { name: 'Meus banners', url: routes.banners.all },
  { name: 'Configurações', url: routes.banners.settings },
]

const CUSTOMIZATION_CARD_TABS = [
  { id: COLOR_CUSTOMIZATION_CARD_ID, tabName: 'Cor' },
  { id: LOGO_CUSTOMIZATION_CARD_ID, tabName: 'Logo' },
  { id: DESCRIPTION_CUSTOMIZATION_CARD_ID, tabName: 'Descrição' },
  { id: POSITION_CUSTOMIZATION_CARD_ID, tabName: 'Posição' },
]

const DEFAULT_DESCRIPTION =
  'Para melhorar a sua experiência na plataforma e prover serviços personalizados, utilizamos cookies. Ao aceitar, você terá acesso a todas as funcionalidades do site. Se clicar em "Rejeitar", os cookies que não forem estritamente necessários serão desativados. Para escolher quais quer autorizar, clique em "Gerenciar cookies".'

const SUPPORTED_IMAGE_FILE_SCHEMA_TYPES = ['image/jpeg', 'image/png']

const SUPPORTED_IMAGE_FILE_INPUT_TYPES = '.jpg,.jpeg,.png'

const CUSTOMIZATION_CARD_CONTENT = {
  [COLOR_CUSTOMIZATION_CARD_ID]: ColorCustomization,
  [LOGO_CUSTOMIZATION_CARD_ID]: LogoUpload,
  [DESCRIPTION_CUSTOMIZATION_CARD_ID]: DescriptionCustomization,
  [POSITION_CUSTOMIZATION_CARD_ID]: PositionCustomization,
}

const CUSTOMIZATION_CARD_PREVIEW = {
  [COLOR_CUSTOMIZATION_CARD_ID]: BannerPreview,
  [LOGO_CUSTOMIZATION_CARD_ID]: LogoPreview,
  [DESCRIPTION_CUSTOMIZATION_CARD_ID]: BannerPreview,
  [POSITION_CUSTOMIZATION_CARD_ID]: PositionPreview,
}

const STATUS_TRANSLATION = {
  [ACTIVE_STATUS_ID]: 'Ativo',
  [INCOMPLETE_STATUS_ID]: 'Incompleto',
  [INACTIVE_STATUS_ID]: 'Inativo',
}

const STATUS_CHIP_COLOR: ObjectOf<ChipColorType> = {
  [ACTIVE_STATUS_ID]: 'success',
  [INCOMPLETE_STATUS_ID]: 'info',
  [INACTIVE_STATUS_ID]: 'neutral',
}

const SCANNER_STATUS_TRANSLATION = {
  [COMPLETED_SCANNER_STATUS_ID]: 'Completo',
  [FAILED_SCANNER_STATUS_ID]: 'Falha',
  [INITIATED_SCANNER_STATUS_ID]: 'Escaneando',
}

const SCANNER_STATUS_COLOR = {
  [COMPLETED_SCANNER_STATUS_ID]: theme.palette.tertiary.main,
  [FAILED_SCANNER_STATUS_ID]: theme.palette.error.main,
  [INITIATED_SCANNER_STATUS_ID]: theme.palette.primary.main,
}

const SCANNER_STATUS_ICON = {
  [COMPLETED_SCANNER_STATUS_ID]: CheckCircleIcon,
  [FAILED_SCANNER_STATUS_ID]: AlertFailedIcon,
  [INITIATED_SCANNER_STATUS_ID]: LoaderIcon,
}

const NEW_BANNER_MODAL_CONTROL_ITEMS: NewBannerModalControlItemsType[] = [
  {
    label: 'Nome',
    controlName: 'name',
  },
  {
    label: 'URL',
    controlName: 'url',
  },
]

const BANNER_CONFIGURATION_CARD_ITEMS: BannerConfigurationCardItemsType[] = [
  {
    label: 'Nome',
    controlName: 'name',
  },
  {
    label: 'URL',
    controlName: 'url',
  },
]

const LEAVENING_ADVANTAGES = [
  {
    name: '1 domínio',
  },
  {
    name: 'Até 1 milhão de visitantes únicos/mes',
  },
  {
    name: 'Scanner de cookies',
  },
  {
    name: 'Compatível com a LGPD',
  },
  {
    name: 'Canal de atendimento integrado',
  },
  {
    name: 'Configuração via HTML ou Google Tag Manager',
  },
]

const ADOPT_ADVANTAGES = [
  {
    name: 'Até 10 domínios',
  },
  {
    name: 'Até 1 milhão de visitantes únicos/mes',
  },
  {
    name: 'Scanner mensal de cookies',
  },
  {
    name: 'Compatível com o GDPR, LGPD, CCPA, RGPD, DSVGO, PIPEDA, CINIL',
  },
  {
    name: 'Homologada pelo Google (CMP)',
  },
  {
    name: 'Configuração via HTML ou Google Tag Manager',
  },
]

const CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS = [
  { value: BANNER_LEFT_POSITION, label: 'Canto inferior esquerdo' },
  { value: BANNER_RIGHT_POSITION, label: 'Canto inferior direito' },
  { value: BANNER_CENTER_POSITION, label: 'Inferior central' },
  { value: BANNER_FIXED_POSITION, label: 'Inferior completo' },
]

export type BannerCustomizationIdType = (typeof CUSTOMIZATION_CARD_IDS)[number]
export type BannerStatusIdType = (typeof STATUSES_IDS)[number] | null

export type BannerScannerStatusIdType =
  | (typeof SCANNER_STATUSES_IDS)[number]
  | null

export type BannerPositionType = (typeof BANNER_POSITION_IDS)[number] | null

type NewBannerModalControlItemsType = {
  label: string
  controlName: NewBannerModalControlType
}

export default {
  ADOPT_ADVANTAGES,
  ADOPT_BANNER_PLAN_ID,
  BANNER_CENTER_POSITION,
  BANNER_CONFIGURATION_CARD_ITEMS,
  BANNER_FIXED_POSITION,
  BANNER_LEFT_POSITION,
  BANNER_RIGHT_POSITION,
  COLOR_CUSTOMIZATION_CARD_ID,
  CONFIGURATION_PAGE_HEADER_CATEGORIES,
  CUSTOMIZATION_CARD_CONTENT,
  CUSTOMIZATION_CARD_PREVIEW,
  CUSTOMIZATION_CARD_TABS,
  CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS,
  DEFAULT_DESCRIPTION,
  INCOMPLETE_STATUS_ID,
  INITIATED_SCANNER_STATUS_ID,
  LEAVENING_ADVANTAGES,
  LEAVENING_BANNER_PLAN_ID,
  LOGO_CUSTOMIZATION_CARD_ID,
  NEW_BANNER_MODAL_CONTROL_ITEMS,
  NO_BANNER_PLAN_ID,
  POSITION_CUSTOMIZATION_CARD_ID,
  SCANNER_STATUS_COLOR,
  SCANNER_STATUS_ICON,
  SCANNER_STATUS_TRANSLATION,
  STATUS_CHIP_COLOR,
  STATUS_TRANSLATION,
  SUPPORTED_IMAGE_FILE_INPUT_TYPES,
  SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
  DESCRIPTION_CUSTOMIZATION_CARD_ID,
}
