import { Controller, useFormContext } from 'react-hook-form'
import { Box, Grid, TextField, Typography } from '@mui/material'

import { CardNumberInput } from 'components'

import { formatters } from 'helpers'

import { CheckoutSchemaType } from 'constants/checkout/form'
import { HandleOnChangeType } from 'views/Checkout/checkout.types'
import { PaymentTextFieldType } from './paymentSection.types'

import { theme } from 'theme'
import constants from 'constants/index'

const PaymentSection = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CheckoutSchemaType>()

  const handleOnChange = ({ event, type }: HandleOnChangeType) => {
    let inputValue = event?.target?.value

    if (type === 'cardExpiringDate')
      inputValue = formatters.cardFlag.expiringDate(inputValue)
    else if (type === 'cvv') inputValue = inputValue.slice(0, 4).toUpperCase()

    setValue(type, inputValue)
  }

  const PaymentTextField = ({ item, index }: PaymentTextFieldType) => (
    <Grid key={index} item xs={item?.gridXs} sm={item?.gridSm}>
      <Box display="flex" gap={1}>
        <Typography color="primary">{item.label}</Typography>
        {item?.required && <Typography color="error">*</Typography>}
      </Box>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.[item?.controlName]}
            helperText={<>{errors?.[item?.controlName]?.message}</>}
            inputProps={{
              inputMode: item?.type,
              style: { backgroundColor: theme.palette.common.white },
            }}
            onChange={(event) =>
              handleOnChange({ event, type: item?.controlName })
            }
            fullWidth
          />
        )}
        control={control}
        name={item?.controlName}
      />
    </Grid>
  )

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h6" color="primary">
        Dados do cartão
      </Typography>
      <Grid container spacing={4}>
        <PaymentTextField
          item={constants.checkout.form.CONTROLLER_PAYMENT_CARD_OWNER_NAME_ITEM}
        />
        <Grid item xs={12} sm={8}>
          <CardNumberInput
            controlName="cardNumber"
            label="Número do cartão"
            inputStyle={{ backgroundColor: theme.palette.common.white }}
            permittedFlags={constants.cardFlags.STANDARD_FLAGS_ITEMS}
            labelOutside
            required
          />
        </Grid>
        {constants.checkout.form.CONTROLLER_PAYMENT_REST_ITEMS.map(
          (item, index) => (
            <PaymentTextField key={index} item={item} index={index} />
          )
        )}
      </Grid>
    </Box>
  )
}

export default PaymentSection
