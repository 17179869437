import axios from 'axios'

import api from 'service/api'
import { CompanyType } from 'types/company.types'
import service from 'service'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet', false)
const baseURL = getBaseURL('dponet')

const create = async (data: CompanyType) =>
  await dponetAPI.post(`/company`, {
    company: data,
  })

const update = async (token: string, data: CompanyType) =>
  await dponetAPI.put(`/company/${token}`, {
    company: data,
  })

const getByToken = async (token: string) =>
  await dponetAPI.get(`/company/${token}`)

const verifyDocument = async (cnpj: string) =>
  await dponetAPI.post(`/companies/verify-document/${cnpj}`)

const consultQRCode = async ({
  token = '',
}: {
  token?: string | number | null
}) => await dponetAPI.get(`/consult_qrcode/${token}`)

const registerAddLogo = async ({
  logo,
  token,
}: {
  logo: File
  token: string
}) => {
  const data = new FormData()
  data.append('logo', logo)
  data.append('token', token)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getRegistrationCompanyToken(),
    },
  }

  return await axios({
    method: 'PUT',
    url: `${baseURL}/company/logo`,
    data,
    ...config,
  })
}

export default {
  consultQRCode,
  create,
  getByToken,
  update,
  registerAddLogo,
  verifyDocument,
}
