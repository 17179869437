import * as yup from 'yup'

import { password } from 'helpers'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .nullable()
      .test(
        'strongPassword',
        'A senha não é forte o suficiente',
        password.validateStrongPassword
      )
      .when(['confirmPassword'], {
        is: (confirmPassword?: string | null) => confirmPassword,
        then: () =>
          yup
            .string()
            .nullable()
            .required()
            .test(
              'strongPassword',
              'A senha não é forte o suficiente',
              password.validateStrongPassword
            ),
      })
      .required(),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        'A confirmação de senha não coincide com a nova senha'
      )
      .required(),
    recaptcha: yup.string().required().nullable(),
    privacyCheck: yup
      .boolean()
      .required('Aceite os Termos do Aviso de privacidade')
      .nullable()
      .isTrue('Aceite os Termos do Aviso de privacidade'),
    usingThermsCheck: yup
      .boolean()
      .required('Aceite os Termos e condições de uso')
      .nullable()
      .isTrue('Aceite os Termos e condições de uso'),
  })
  .required()

export type WelcomePlanFormSchemaType = yup.InferType<typeof schema>

export type WelcomePlanFormControlType = keyof WelcomePlanFormSchemaType

export default schema
