import { regex } from 'helpers'

import { PersonalFormSchemaType } from 'constants/settings'

const passwordData = (data: PersonalFormSchemaType) => {
  return {
    currentPassword: data?.currentPassword,
    newPassword: data?.newPassword,
    confirmPassword: data?.confirmPassword,
  }
}

const personalData = (data: PersonalFormSchemaType) => {
  return {
    email: data?.email,
    name: data?.name,
    phone: regex.onlyNumbers(data?.phone),
  }
}

export default {
  passwordData,
  personalData,
}
