import { Grid, Typography } from '@mui/material'

import { MUI } from 'components'
import { CardPlan } from 'views/WelcomeSteps/components'

import constants from 'constants/index'

import leaveningLogo from 'images/platforms/leavening.png'
import adoptLogo from 'images/platforms/adopt.png'

const PlanSelect = ({
  selected,
  setSelected,
}: {
  selected?: number
  setSelected: (plan: number) => void
}) => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h6" textAlign="center">
          Selecione o plano desejado
        </Typography>
      </Grid>
      <Grid item md={6} xs={12} onClick={() => setSelected(1)}>
        <CardPlan
          isCentered
          advantages={constants.banner.LEAVENING_ADVANTAGES}
          selected={selected === constants.banner.LEAVENING_BANNER_PLAN_ID}
          title={<MUI.Img src={leaveningLogo} height="60px" />}
          subtitle="R$15,00"
          fullHeight
        />
      </Grid>
      <Grid item md={6} xs={12} onClick={() => setSelected(2)}>
        <CardPlan
          isCentered
          advantages={constants.banner.ADOPT_ADVANTAGES}
          selected={selected === constants.banner.ADOPT_BANNER_PLAN_ID}
          title={<MUI.Img src={adoptLogo} height="60px" />}
          subtitle="R$62,00"
          fullHeight
        />
      </Grid>
    </Grid>
  )
}

export default PlanSelect
