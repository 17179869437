import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTrait = api.create('dponet', false)

const me = async () => await dponetAPI.get(`/me`)

const create = async (data: object) =>
  await dponetAPINotTrait.post(`/users`, data)

const update = async (data = {}) => {
  return await dponetAPI.put(`/users/update`, data)
}

export default {
  me,
  create,
  update,
}
