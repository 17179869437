import { CheckoutSchemaType } from 'constants/checkout/form'
import { getPaymentAuthKey } from 'service/env'

const mountCheckoutData = (
  data: CheckoutSchemaType,
  preRegistrationToken?: string
) => ({
  authKey: getPaymentAuthKey(),
  paymentOrder: {
    method: 1,
    card: {
      name: data?.cardOwnerName,
      number: data?.cardNumber.split(' ').join(''),
      cvv: data?.cvv,
      expiration: data?.cardExpiringDate,
    },
    preRegistrationToken,
  },
})

export default mountCheckoutData
