import baseURL from './baseURL'
import cardFlag from './cardFlag'
import cep from './cep'
import cnpj from './cnpj'
import date from './date'
import errorMessage from './errorMessage'
import file from './file'
import formatToCurrencyBrl from './formatToCurrencyBrl'
import maturityPoints from './maturityPoints'
import mountCheckoutData from './mountCheckoutData'
import mountPaymentData from './mountPaymentData'
import phoneDynamicMask from './phoneDynamicMask'
import website from './website'

export default {
  baseURL,
  cardFlag,
  cep,
  cnpj,
  date,
  errorMessage,
  file,
  formatToCurrencyBrl,
  maturityPoints,
  mountCheckoutData,
  mountPaymentData,
  phoneDynamicMask,
  website,
}
