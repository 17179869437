import { createContext } from 'react'

import { PrivacyChannelContextType } from 'types/privacyChannel.types'

const contextValues: PrivacyChannelContextType = {
  templates: [],
  setTemplates: () => {},
  dataUpdateStep: undefined,
  setDataUpdateStep: () => {},
  dataDestroyStep: undefined,
  setDataDestroyStep: () => {},
  defaultsDataTreatments: [],
  setDefaultsDataTreatments: () => {},
  customDataTreatments: [],
  setCustomDataTreatments: () => {},
  defaultDataShares: [],
  setDefaultDataShares: () => {},
  customDataShares: [],
  setCustomDataShares: () => {},
  showFinishTemplateButton: false,
  setShowFinishTemplateButton: () => {},
  finishJourney: () => {},
}

const privacyChannelContext = createContext(contextValues)

export default privacyChannelContext
