import { useEffect, useMemo } from 'react'
import { usePalette } from 'react-palette'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Grid } from '@mui/material'

import { CompanyHighlightCard, DefaultController } from './components'
import { BasicSection } from 'components'

import { fileControl, formatters } from 'helpers'

import { useAuth, useSnackbar } from 'hooks'

import { CompanyFormSchemaType } from 'constants/settings'
import { CompanyFormType } from './companyForm.types'

import schema from './schema'
import constants from 'constants/index'
import service from 'service'

const CompanyForm = ({ loading, setLoading }: CompanyFormType) => {
  const { userCompany, loadData } = useAuth()
  const { snackbar } = useSnackbar()
  const { handleSubmit, reset, watch, ...formRest } =
    useForm<CompanyFormSchemaType>({
      resolver: yupResolver(schema),
      defaultValues: {
        description: userCompany?.company?.description || '',
        email: userCompany?.company?.email || '',
        name: userCompany?.company?.name || '',
        phone: formatters.phoneDynamicMask(userCompany?.company?.phone) || '',
        siteUrl: userCompany?.company?.siteUrl || '',
        logo: undefined,
      },
    })

  const formId = 'company-form'

  const selectedImage = watch('logo')

  const logoUrl = userCompany?.company?.logo?.url
  const companyLogo = logoUrl && formatters.baseURL(logoUrl)

  const logo = useMemo(() => {
    return fileControl.handleImageChange(selectedImage, companyLogo)
  }, [selectedImage, companyLogo])

  const { data: reactPaletteData, loading: reactPaletteLoading } =
    usePalette(logo)

  const onSubmit = async (data: CompanyFormSchemaType) => {
    try {
      setLoading(true)
      const { logo, ...companyData } = data

      await service.dponet.userCompany.update(companyData)
      if (logo) await service.dponet.userCompany.updateLogo(logo as File)

      snackbar.open({
        message: `Dados da empresa atualizados com sucesso!`,
        variant: 'success',
      })
      reset({})
      loadData()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading((reactPaletteLoading) => reactPaletteLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPaletteLoading])

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      watch={watch}
      reset={reset}
      {...formRest}
    >
      <Box
        component="form"
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={8}
      >
        {!reactPaletteLoading && (
          <CompanyHighlightCard reactPalette={reactPaletteData} logo={logo} />
        )}
        <BasicSection title="Dados básicos">
          <Grid container spacing={4}>
            {constants.settings.BASIC_DATA_CONTROLLER_ITEMS_FROM_COMPANY_DATA.map(
              (item, index) => (
                <Grid
                  key={index}
                  item
                  xs={item?.gridXs}
                  sm={item?.gridSm}
                  md={item?.gridMd}
                >
                  <DefaultController item={item} />
                </Grid>
              )
            )}
          </Grid>
        </BasicSection>
        <Box display="flex" justifyContent="end">
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            type="submit"
            form={formId}
          >
            Salvar dados da empresa
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default CompanyForm
