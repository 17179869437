import { PrivacyChannelContext } from 'contexts'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth, useJourney } from 'hooks'

import { NodeChildren } from 'types/global.types'
import {
  DataDestroyType,
  DataUpdateType,
  TemplateType,
} from 'types/privacyChannel.types'

import constants from 'constants/index'
import service from 'service'
import routes from 'constants/routes'
import { LoadingFeedback } from 'components'

const PrivacyChannelProvider = ({ children }: NodeChildren) => {
  const { activeStep } = useJourney()
  const { loadCompanyMaturity, companyMaturity } = useAuth()
  const navigate = useNavigate()

  const [templates, setTemplates] = useState<TemplateType[]>(
    constants.privacyChannelConfigurationSteps.TEMPLATE_PLATFORMS
  )
  const [dataUpdateStep, setDataUpdateStep] = useState<
    DataUpdateType | undefined
  >()
  const [dataDestroyStep, setDataDestroyStep] = useState<
    DataDestroyType | undefined
  >()
  const [defaultsDataTreatments, setDefaultsDataTreatments] = useState<
    TemplateType[]
  >(constants.privacyChannelConfigurationSteps.DATA_TREATMENTS_DEFAULTS)
  const [customDataTreatments, setCustomDataTreatments] = useState<
    TemplateType[]
  >(constants.privacyChannelConfigurationSteps.DATA_TREATMENTS_CUSTOM)
  const [defaultDataShares, setDefaultDataShares] = useState<TemplateType[]>(
    constants.privacyChannelConfigurationSteps.DATA_SHARE_DEFAULT
  )
  const [customDataShares, setCustomDataShares] = useState<TemplateType[]>(
    constants.privacyChannelConfigurationSteps.DATA_SHARE_CUSTOM
  )
  const [showFinishTemplateButton, setShowFinishTemplateButton] =
    useState(false)
  const [loading, setLoading] = useState(false)

  const getOnlyActive = (data: TemplateType[]) =>
    data.filter((item) => item.active).map((item) => item.name)

  const finishJourney = async () => {
    setLoading(true)
    const alreadyConfigured = companyMaturity?.privacyChannelConfigured

    const params = {
      configurations: {
        dataTreatments: [
          ...getOnlyActive(defaultsDataTreatments),
          ...getOnlyActive(customDataTreatments),
        ],
        dataShares: [
          ...getOnlyActive(defaultDataShares),
          ...getOnlyActive(customDataShares),
        ],
        dataDestroy: {
          description: dataDestroyStep?.description,
          active: dataDestroyStep?.destroyTheirOwnData,
        },
        dataUpdate: {
          description: dataUpdateStep?.description,
          active: dataUpdateStep?.updateTheirOwnData,
        },
      },
    }

    await service.dponet.answerOptionTicket.configure({ data: params })
    await loadCompanyMaturity()

    setLoading(false)

    navigate(routes.serviceChannel.automaticAnswer, {
      state: { openTutorial: !alreadyConfigured },
    })
  }

  useEffect(() => {
    if (showFinishTemplateButton) setShowFinishTemplateButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <PrivacyChannelContext.Provider
      value={{
        templates,
        setTemplates,
        dataUpdateStep,
        setDataUpdateStep,
        dataDestroyStep,
        setDataDestroyStep,
        defaultsDataTreatments,
        setDefaultsDataTreatments,
        customDataTreatments,
        setCustomDataTreatments,
        defaultDataShares,
        setDefaultDataShares,
        customDataShares,
        setCustomDataShares,
        showFinishTemplateButton,
        setShowFinishTemplateButton,
        finishJourney,
      }}
    >
      <LoadingFeedback open={loading} />
      {children}
    </PrivacyChannelContext.Provider>
  )
}

export default PrivacyChannelProvider
