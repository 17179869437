import axios from 'axios'

import service from 'service'
import api from 'service/api'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const dponetAPINotTrait = api.create('dponet', false)

const dponetURL = getBaseURL('dponet')

const identify = async () => await dponetAPI.get(`/identify`)

const postCheckout = async (data: object) =>
  await dponetAPINotTrait.post(`/post-checkout`, data)

const update = async (data = {}) => {
  return dponetAPI.put(`/company/update_self`, { company: data })
}

const updateLogo = async (logo: File) => {
  const data = new FormData()

  data.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'PUT',
    url: `${dponetURL}/company/update_self_logo`,
    data: data,
    ...config,
  })
}

export default {
  identify,
  postCheckout,
  updateLogo,
  update,
}
