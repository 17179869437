import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'

import { TextField } from './components'
import { PasswordValidation } from 'components'

import { formatters } from 'helpers'

import { useJourney, useRegistration, useSnackbar } from 'hooks'

import schema from './schema'
import service from 'service'
import routes from 'constants/routes'
import constants from 'constants/index'
import { getGoogleRecaptchaToken } from 'service/env'

const FormUser = () => {
  const [anyError, setAnyError] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const { setNextDisabled, setActiveStep } = useJourney()
  const { snackbar } = useSnackbar()

  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const { company, recaptcha, loadOut, setRecaptcha } = useRegistration()

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods

  useEffect(() => {
    setMounted(true)
    setNextDisabled(!recaptcha)

    return () => setMounted(false)
    //eslint-disable-next-line
  }, [])

  const handleEditCompany = () => setActiveStep(0)

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setNextDisabled(true)
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setNextDisabled(false)
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const onSubmit = async (data: {
    email: string
    password: string
    confirmPassword: string
  }) => {
    try {
      setNextDisabled(true)

      await service.dponet.user.create({
        user: {
          ...data,
          phone: company?.phone,
          name: company?.name,
          recaptcha,
        },
      })

      await service.dponet.userCompany.postCheckout({
        userEmail: data?.email,
        companyToken: company?.token,
        bannerToken: service.dpoeasy.auth.getCompanyBannerToken(),
      })

      try {
        await service.dponet.auth.login({
          email: data?.email,
          password: data?.password,
        })
      } finally {
        loadOut()
        if (service.dponet.auth.isAuthenticated()) {
          navigate(routes.root)
        } else {
          navigate(routes.auth.login)
        }
      }
    } catch (error) {
      setAnyError(true)
      resetRecaptcha()
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setNextDisabled(false)
    }
  }

  if (!mounted) {
    return <></>
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="700" color="primary">
          Dados do usuário
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              {constants.welcomeSteps.USER_DATA_CONTROL_ITEMS.map(
                (item, index) => (
                  <Grid key={index} item xs={12} lg={item?.gridLg}>
                    <TextField
                      label={item?.label}
                      name={item?.controlName}
                      type={item?.type}
                      errors={errors}
                      control={control}
                    />
                  </Grid>
                )
              )}
              <Grid item xs={12}>
                <PasswordValidation />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                  {constants.welcomeSteps.USER_DATA_TERMS_CONTROL_ITEMS.map(
                    (item, index) => (
                      <Controller
                        key={index}
                        render={({ field }) => (
                          <FormControl {...field}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color={
                                    errors?.[item?.controlName]
                                      ? 'error'
                                      : 'primary'
                                  }
                                />
                              }
                              label={
                                <Typography
                                  color={
                                    errors?.[item?.controlName]
                                      ? 'error'
                                      : 'primary'
                                  }
                                >
                                  {item?.label}
                                </Typography>
                              }
                            />
                            {!!errors?.[item?.controlName] && (
                              <FormHelperText
                                error={!!errors?.[item?.controlName]}
                              >
                                {<>{errors?.[item?.controlName]?.message}</>}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                        control={control}
                        name={item?.controlName}
                      />
                    )
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <ReCAPTCHA
                      {...field}
                      ref={recaptchaRef}
                      sitekey={getGoogleRecaptchaToken()}
                      onChange={(token) => handleRecaptcha(token)}
                      onExpired={expiredRecaptcha}
                      size="normal"
                    />
                  )}
                  name="recaptcha"
                  control={control}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
      {!!anyError && (
        <Grid item xs={12}>
          <Button variant="contained" color="error" onClick={handleEditCompany}>
            Editar dados da empresa
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default FormUser
