import { HTMLInputTypeAttribute } from 'react'
import { WelcomePlanFormControlType } from 'views/WelcomeSteps/steps/Plan/components/Form/schema'

import hello from 'images/hello.png'
import make from 'images/make.gif'
import find from 'images/find.png'
import policies from 'images/policies.png'
import stampStep from 'images/stamp-step.png'
import userInterface from 'images/user_interface.png'

const STEPS = [
  {
    image: hello,
    isForm: true,
    text: 'Bem-vindo à LGPD Fácil, a sua plataforma de gestão da LGPD simplificada. Vamos embarcar juntos em uma rápida jornada para nos conhecermos melhor. Vamos começar?',
  },
  {
    image: make,
    isForm: true,
    text: 'Por favor, confirme o principal setor de atuação da sua organização. Com base nessa informação, nós faremos todo o trabalho pesado para você, simplificando o processo. Você só precisará confirmar e ajustar conforme necessário.',
  },
  {
    image: find,
    isForm: false,
    text: 'Cookies são pequenos arquivos de texto usados por sites para armazenar informações sobre a atividade do usuário, personalizando a experiência de navegação e oferecendo funcionalidades específicas.',
  },
  {
    image: userInterface,
    isForm: false,
    text: 'Um banner de cookies é um aviso exibido nos sites para informar e obter o consentimento dos usuários antes de rastrear e armazenar seus dados de navegação.',
  },
  {
    image: policies,
    isForm: false,
    text: 'Com base nas suas informações nossa Inteligência Artificial está gerando uma Política de Cookies e um Aviso de Privacidade para sua organização.',
  },
  {
    image: stampStep,
    isForm: false,
    text: 'Um selo para os titulares de dados facilita a comunicação com a organização, evidenciando conformidade com regulamentações de proteção de dados, promovendo transparência e confiança na gestão das informações pessoais.',
  },
  {
    image: find,
    isForm: true,
    text: 'Cookies são pequenos arquivos de texto usados por sites para armazenar informações sobre a atividade do usuário, personalizando a experiência de navegação e oferecendo funcionalidades específicas.',
  },
  {
    image: userInterface,
    isForm: true,
    text: 'Nesta etapa, você fornecerá as informações de pagamento, como número do cartão, data de validade e código de segurança, além de escolher o plano que melhor se adapta às suas necessidades.',
  },
]

const COMPANY_LOGO_RADIO_BUTTON_CONTROL_ITEMS = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

const USER_DATA_CONTROL_ITEMS: UserDataControlItems[] = [
  { controlName: 'email', label: 'E-mail', type: 'email' },
  { controlName: 'password', label: 'Senha', type: 'password', gridLg: 6 },
  {
    controlName: 'confirmPassword',
    label: 'Confirmar senha',
    type: 'password',
    gridLg: 6,
  },
]

const USER_DATA_TERMS_CONTROL_ITEMS: UserDataControlItems[] = [
  {
    controlName: 'privacyCheck',
    label: 'Li e concordo com o Aviso de privacidade',
  },
  {
    controlName: 'usingThermsCheck',
    label: 'Li e concordo com os Termos e condições de uso',
  },
]

type UserDataControlItems = {
  controlName: WelcomePlanFormControlType
  label: string
  type?: HTMLInputTypeAttribute
  gridLg?: number
}

export default {
  STEPS,
  COMPANY_LOGO_RADIO_BUTTON_CONTROL_ITEMS,
  USER_DATA_CONTROL_ITEMS,
  USER_DATA_TERMS_CONTROL_ITEMS,
}
