import { CheckoutSchemaType } from 'constants/checkout/form'
import { CompanyMinimalType } from 'types/company.types'
import { UserType } from 'types/user.types'

const mountCheckoutData = (
  recaptcha: string,
  data: CheckoutSchemaType,
  company?: CompanyMinimalType,
  user?: UserType
) => ({
  preRegistration: {
    recaptcha,
    name: company?.name,
    fantasyName: company?.name,
    document: company?.cnpj,
    kind: 'C',
    phone: user?.phone,
    email: user?.email,
    plan_id: data?.companySize,
    type: 'REAL',
    address: {
      postalCode: data?.cep,
      country: 'Brasil',
      state: data?.state,
      city: data?.city,
      complement: data?.complement,
      neighborhood: data?.street,
      street: data?.street,
      number: data?.number,
    },
    responsibleUser: {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
    },
  },
})

export default mountCheckoutData
