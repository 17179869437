import { useEffect, useState } from 'react'

import { Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { useJourney, useRegistration, useSnackbar } from 'hooks'

import cookieScan from 'images/cookie_scan.gif'
import service from 'service'
import { formatters } from 'helpers'

const Welcome = () => {
  const { snackbar } = useSnackbar()
  const [stage, setStage] = useState(0)
  const [intervalState, setIntervalState] = useState<NodeJS.Timeout>()

  const {
    bannerCreated,
    company,
    companyChanged,
    createCompany,
    createBanner,
    setBannerCreated,
  } = useRegistration()
  const { setNextDisabled, handleNext, setActiveStep } = useJourney()
  const bannerShouldUpdated = !bannerCreated || companyChanged

  const checkUrl = async () => {
    try {
      if (!bannerCreated) {
        await service.dpoeasy.banner.checkUrl({
          banner: { url: company?.siteUrl },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const createCompanyBanner = async () => {
    try {
      setNextDisabled(true)
      await createCompany()
      await createBanner()
      setNextDisabled(false)
    } catch (error) {
      setNextDisabled(false)

      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error),
      })
      setActiveStep(0)
    }
  }

  const scanCookies = async () => {
    try {
      if (!bannerCreated) {
        const token = service.dpoeasy.auth.getCompanyBannerToken()
        if (token) {
          await service.dpoeasy.banner.scannerByToken(token)
        }
      }
    } catch (e) {
      console.error(e)
      // setStageError('functionalCookies', true, formatters.errorMessage(e))
    }
  }

  const concluded = () => {
    setNextDisabled(false)

    const newInterval = setTimeout(handleNext, 1500)
    setIntervalState(newInterval)
  }

  useEffect(() => {
    const runStage = async () => {
      if (stage === 1) {
        await checkUrl()
        return setStage(stage + 1)
      }

      if (stage === 2) {
        await createCompanyBanner()
        return setStage(stage + 1)
      }

      if (stage === 3) {
        await scanCookies()
        return setStage(stage + 1)
      }

      if (stage === 4) {
        setBannerCreated(true)
        concluded()
        return setStage(stage + 1)
      }
    }

    runStage()
    //eslint-disable-next-line
  }, [stage])

  useEffect(() => {
    if (bannerShouldUpdated && stage === 0) {
      setNextDisabled(true)
      setStage(1)
    }

    return () => {
      setNextDisabled(false)
      if (intervalState) {
        clearInterval(intervalState)
      }
    }

    //eslint-disable-next-line
  }, [intervalState, stage])

  return (
    <Grid container spacing={6} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Estamos verificando os cookies do seu site
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Esse processo geralmente leva cerca de 15 segundos
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <MUI.Img
          $maxHeight="30vh"
          $maxWidth="100%"
          src={cookieScan}
          alt="indicador scan do site"
        />
      </Grid>
    </Grid>
  )
}

export default Welcome
