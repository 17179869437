import { InferType } from 'yup'

import DPOEasy from 'components/Svg/DPOEasy'
import DPOnet from 'components/Svg/DPOnet'

import { InputModeType, SVGComponentType } from 'types/global.types'
import { CardPlansItems } from './planCard'

import planCardConstants from 'constants/checkout/planCard'
import schema from 'views/Checkout/components/CheckoutForm/schema'

const CONTROLLER_ADDRESS_ITEMS: ControllerGlobalItemsType[] = [
  {
    label: 'Código Postal (CEP)',
    controlName: 'cep',
    required: true,
    gridSm: 4,
    gridMd: 4,
    type: 'numeric',
  },
  {
    label: 'Rua / Logradouro',
    controlName: 'street',
    required: true,
    gridSm: 8,
    gridMd: 8,
    type: 'text',
  },
  {
    label: 'Número',
    controlName: 'number',
    required: true,
    gridSm: 4,
    gridMd: 2,
    type: 'numeric',
  },
  {
    label: 'Complemento',
    controlName: 'complement',
    gridSm: 8,
    gridMd: 4,
    type: 'text',
  },
  {
    label: 'Cidade',
    controlName: 'city',
    required: true,
    gridSm: 9,
    gridMd: 4,
    type: 'text',
  },
  {
    label: 'Estado',
    controlName: 'state',
    required: true,
    gridSm: 3,
    gridMd: 2,
    type: 'text',
  },
]

const COMPANY_SIZE_CARD_RADIO_CONTENT = [
  {
    title: 'Até 1.000 clientes',
    subtitle: 'e-commerce',
    cost: 'R$ 150,00/mês',
    radioValue: '12',
  },
  {
    title: 'De 1.000 a 10.000 clientes',
    subtitle: 'e-commerce',
    cost: 'R$ 300,00/mês',
    radioValue: '13',
  },
  {
    title: 'Acima de 10.000 clientes',
    subtitle: 'e-commerce',
    cost: 'R$ 450,00/mês',
    radioValue: '14',
  },
]

const CONTROLLER_PLAN_CARDS: ControllerPlanCardsType[] = [
  {
    logo: DPOEasy,
    radioValue: 'free',
    content: planCardConstants.DPO_EASY_PLAN_CARD_ITEMS,
    disable: true,
  },
  {
    logo: DPOnet,
    radioValue: 'dponet',
    content: planCardConstants.DPO_NET_PLAN_CARD_ITEMS,
  },
]

const CONTROLLER_PAYMENT_CARD_OWNER_NAME_ITEM: ControllerGlobalItemsType = {
  controlName: 'cardOwnerName',
  gridXs: 12,
  gridSm: 12,
  label: 'Nome do titular do cartão',
  required: true,
  type: 'text',
}

const CONTROLLER_PAYMENT_REST_ITEMS: ControllerGlobalItemsType[] = [
  {
    controlName: 'cardExpiringDate',
    gridXs: 6,
    gridSm: 2,
    label: 'Validade',
    required: true,
    type: 'numeric',
  },
  {
    controlName: 'cvv',
    gridXs: 6,
    gridSm: 2,
    label: 'CVV',
    required: true,
    type: 'numeric',
  },
]

const CONTROLLER_TERMS_ITEMS: ControllerTermsItems[] = [
  {
    label: 'Li e concordo com o Aviso de privacidade',
    controlName: 'privacyNotice',
  },
  {
    label: 'Li e concordo com os Termos e condições de uso',
    controlName: 'terms',
  },
]

export type CheckoutSchemaType = InferType<typeof schema>
export type CheckoutControlType = keyof CheckoutSchemaType

export type ControllerGlobalItemsType = {
  controlName: CheckoutControlType
  gridXs?: number
  gridSm?: number
  gridMd?: number
  label: string
  required?: boolean
  type?: InputModeType
}

export type ControllerPlanCardsType = {
  logo: SVGComponentType
  radioValue: 'free' | 'dponet'
  content: CardPlansItems[]
  disable?: boolean
}

export type ControllerTermsItems = {
  label: string
  controlName: CheckoutControlType
}

export default {
  CONTROLLER_ADDRESS_ITEMS,
  COMPANY_SIZE_CARD_RADIO_CONTENT,
  CONTROLLER_PLAN_CARDS,
  CONTROLLER_PAYMENT_CARD_OWNER_NAME_ITEM,
  CONTROLLER_PAYMENT_REST_ITEMS,
  CONTROLLER_TERMS_ITEMS,
}
