import { validates } from 'helpers'

import * as yup from 'yup'

const schema = yup.object({
  plan: yup.string().required(),
  companySize: yup.string().required('Escolha uma opção'),
  street: yup.string().min(4).max(150).required(),
  number: yup.string().max(10).required(),
  complement: yup.string().max(100),
  cep: yup.string().length(9, 'CEP inválido').required(),
  city: yup.string().min(4).max(50).required(),
  state: yup.string().length(2, 'Estado inválido').required(),
  cardOwnerName: yup.string().min(4).max(150).required(),
  cardNumber: yup
    .string()
    .min(16)
    .max(23)
    .required()
    .test(
      'cardNumberIsValid',
      'Cartão inválido',
      (value) => !!validates.cardFlag.id(value)
    ),
  cardExpiringDate: yup.string().length(5, 'Data inválida').required(),
  cvv: yup
    .string()
    .min(3, 'Código Inválido')
    .max(4, 'Código Inválido')
    .required(),
  privacyNotice: yup
    .bool()
    .oneOf([true], 'Concorde com o Aviso de privacidade')
    .required(),
  terms: yup
    .bool()
    .oneOf([true], 'Concorde com os Termos e condições de uso')
    .required(),
  recaptcha: yup.string().required('Complete o recaptcha'),
})

export default schema
