import { Container } from '@mui/material'

import { styled } from '@mui/material/styles'

const WrapperBox = styled(Container)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    minHeight: '85vh',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}))

export default WrapperBox
