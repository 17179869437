import { Grid, Typography } from '@mui/material'
import { CardPlan } from 'views/WelcomeSteps/components'
import { FormUser } from './components'

const Plan = () => {
  const advantages = [
    {
      name: 'Canal de Atendimento',
    },
    {
      name: 'Banner de Cookies',
    },
    {
      name: 'Políticas',
    },
    {
      name: '100 mil acessos',
    },
    {
      name: '1 site',
    },
    {
      name: 'Respostas Automáticas',
    },
  ]

  return (
    <Grid container spacing={6} justifyContent="stretch">
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Finalizar conta
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Destaque o seu canal de atendimento com os selo. Escolha entre várias
          opções de formatos que preparamos para você
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={6} alignItems="center">
          <Grid item xl={4} lg={5} md={6} xs={12}>
            <CardPlan title="DPO EASY" advantages={advantages} selected />
          </Grid>
          <Grid item xl={8} lg={7} md={6} xs={12}>
            <FormUser />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Plan
