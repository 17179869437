/* eslint-disable @typescript-eslint/no-unused-vars */
import policies from 'images/policies.png'

import tray from 'images/platforms/tray.png'
import magazord from 'images/platforms/magazord_logo.jpeg'
import shopify from 'images/platforms/shopify.png'
import vtex from 'images/platforms/vtex.jpg'
import nuvemshop from 'images/platforms/nuvem_shop.png'
import lojaIntegrada from 'images/platforms/loja_integrada.jpg'
import locaweb from 'images/platforms/locaweb.png'
import rdCrm from 'images/platforms/rd-crm.png'
import vindi from 'images/platforms/vindi.png'

import {
  Home,
  Mail,
  Phone,
  ShoppingBag,
  Calendar,
  User,
  Wifi,
  DollarSign,
  Truck,
  Heart,
  Archive,
  Cloud,
} from 'react-feather'

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'

import { TemplateType } from 'types/privacyChannel.types'

const STEPS = [
  {
    video: 'https://player.vimeo.com/video/942117012',
    isForm: true,
    text: 'Bem-vindo à jornada de configuração do seu canal de atendimento. Responda às  perguntas a seguir, para automatizarmos as respostas aos titulares de dados.',
  },
  {
    video: 'https://player.vimeo.com/video/942116491',
    isForm: false,
    text: 'Para que diversas atividades do seu negócio ocorram, é necessário coletar dados pessoais de seus clientes e funcionários, tais como nome, endereço, telefone, entre outros.',
  },
  {
    video: 'https://player.vimeo.com/video/942115726',
    isForm: false,
    text: 'Para a realização de algumas atividades, pode ser necessário compartilhar dados com parceiros. Por exemplo, ao enviar um produto, é preciso compartilhar o endereço com uma transportadora.',
  },
  {
    video: 'https://player.vimeo.com/video/942115237',
    isForm: true,
    text: 'Um dos direitos do titular é solicitar a correção de seus dados. Precisamos entender se ele pode fazer isso por conta própria ou se você precisa auxiliá-lo.',
  },
  {
    video: 'https://player.vimeo.com/video/942111895',
    isForm: true,
    text: 'Um dos direitos do titular é solicitar a exclusão de seus dados. Precisamos entender se ele pode fazer isso por conta própria ou se você precisa auxiliá-lo.',
  },
  {
    image: policies,
    isForm: true,
    text: 'Com base nas suas informações nossa Inteligência Artificial gerou respostas automáticas para as solicitações dos titulares de dados.',
  },
]

const TEMPLATE_TRAY = {
  nameTemplate: 'Tray',
  dataTreatments: [
    'Nome completo',
    'E-mail',
    'CPF',
    'RG',
    'Data de Nascimento',
    'Telefone',
    'Endereço',
    'Dados de cobrança',
  ],
  dataShare: [
    'Bancos',
    'Instituições financeiras',
    'Marketplaces',
    'Transportadoras',
    'Plataformas de Marketing',
    'Serviços de mensagens',
    'Redes sociais',
    'Emissor de NF-e',
  ],
  dataUpdate: {
    description:
      'Você poderá editar os seus dados de cadastro clicando em Editar Dados, dentro da Central do Cliente.',
    updateTheirOwnData: true,
  },
  dataDestroy: {
    description:
      'Entre em contato diretamente com a loja, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a exclusão interna dos seus dados de cadastro.',
    destroyTheirOwnData: true,
  },
}

const TEMPLATE_LOCAWEB = {
  nameTemplate: 'E-mail Locaweb',
  dataTreatments: [
    'Nome completo',
    'E-mail',
    'CPF',
    'Telefone',
    'Dados de cobrança',
  ],
  dataShare: [
    'Servidor de e-mails',
    'Bancos',
    'Serviços de cobrança',
    'Provedores de internet',
  ],
  dataUpdate: {
    description:
      'Entre em contato diretamente, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a alteração interna dos seus dados de cadastro.',
    updateTheirOwnData: true,
  },
  dataDestroy: {
    description:
      'Entre em contato diretamente através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a exclusão interna dos seus dados de cadastro.',
    destroyTheirOwnData: false,
  },
}

const TEMPLATE_RD_MARKETING = {
  nameTemplate: 'RD Marketing',
  dataTreatments: [
    'Nome completo',
    'E-mail',
    'Telefone',
    'Cidade',
    'Cargo',
    'Empresa',
    'CNPJ',
  ],
  dataShare: [
    'Servidor de e-mails',
    'Serviços de mensagens instanâneas',
    'Redes sociais',
    'Ferramentas de Pesquisa',
  ],
  dataUpdate: {
    description:
      'Entre em contato diretamente com a empresa, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a alteração interna dos seus dados de cadastro.',
    updateTheirOwnData: true,
  },
  dataDestroy: {
    description:
      'Entre em contato diretamente com a empresa, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a exclusão interna dos seus dados de cadastro.',
    destroyTheirOwnData: true,
  },
}

const TEMPLATE_RD_CRM = {
  nameTemplate: 'RD CRM',
  dataTreatments: [
    'Nome completo',
    'E-mail',
    'Telefone',
    'Cidade',
    'Cargo',
    'Empresa',
    'CNPJ',
  ],
  dataShare: [
    'Servidor de e-mails',
    'Serviços de mensagens instanâneas',
    'Redes sociais',
    'Ferramentas de Pesquisa',
  ],
  dataUpdate: {
    description:
      'Entre em contato diretamente com a empresa, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a alteração interna dos seus dados de cadastro.',
    updateTheirOwnData: true,
  },
  dataDestroy: {
    description:
      'Entre em contato diretamente com a empresa, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a exclusão interna dos seus dados de cadastro.',
    destroyTheirOwnData: true,
  },
}

const TEMPLATE_VINDI = {
  nameTemplate: 'Vindi',
  dataTreatments: [
    'Nome completo',
    'E-mail',
    'CPF',
    'Telefone',
    'Dados de cobrança',
  ],
  dataShare: [
    'Bancos',
    'Instituições financeiras',
    'Marketplaces',
    'Transportadoras',
    'Plataformas de Marketing',
    'Serviços de mensagens',
    'Redes sociais',
    'Emissor de NF-e',
  ],
  dataUpdate: {
    description:
      'Entre em contato diretamente  através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a alteração interna dos seus dados de cadastro.',
    updateTheirOwnData: true,
  },
  dataDestroy: {
    description:
      'Entre em contato diretamente com a loja, através dos nossos canais de atendimento (Telefone, Whatsapp ou e-mail) para que possamos realizar a exclusão interna dos seus dados de cadastro.',
    destroyTheirOwnData: true,
  },
}

const NONE = 'Nenhuma'

const TEMPLATE_PLATFORMS = [
  {
    active: false,
    name: 'Tray',
    image: tray,
    template: TEMPLATE_TRAY,
  },
  {
    active: false,
    name: 'Magazord',
    image: magazord,
    template: TEMPLATE_TRAY,
  },
  {
    active: false,
    name: 'Shopify',
    image: shopify,
    template: TEMPLATE_TRAY,
  },
  {
    active: false,
    name: 'Nuvemshop',
    image: nuvemshop,
    template: TEMPLATE_TRAY,
  },
  {
    active: false,
    name: 'Loja Integrada',
    image: lojaIntegrada,
    template: TEMPLATE_TRAY,
  },
  {
    active: false,
    name: 'Vtex',
    image: vtex,
    template: TEMPLATE_TRAY,
  },
  // {
  //   active: false,
  //   name: 'E-mail Locaweb',
  //   image: locaweb,
  //   template: TEMPLATE_LOCAWEB,
  // },
  // {
  //   active: false,
  //   name: 'RD Marketing',
  //   image: rdCrm,
  //   template: TEMPLATE_RD_MARKETING,
  // },
  // {
  //   active: false,
  //   name: 'RD CRM',
  //   image: rdCrm,
  //   template: TEMPLATE_RD_CRM,
  // },
  // {
  //   active: false,
  //   name: 'Vindi',
  //   image: vindi,
  //   template: TEMPLATE_VINDI,
  // },
  {
    active: false,
    name: NONE,
  },
]

const DATA_TREATMENTS_DEFAULTS = [
  {
    active: true,
    name: 'Nome completo',
    icon: User,
  },
  {
    active: true,
    name: 'E-mail',
    icon: Mail,
  },
  {
    active: true,
    name: 'Telefone',
    icon: Phone,
  },
  {
    active: true,
    name: 'Documento',
    icon: ShoppingBag,
  },
  {
    active: true,
    name: 'Endereço',
    icon: Home,
  },
  {
    active: true,
    name: 'Idade',
    icon: Calendar,
  },
]

const DATA_TREATMENTS_CUSTOM: TemplateType[] = []

const DATA_SHARE_DEFAULT = [
  {
    active: false,
    icon: ShoppingBag,
    name: 'Plataforma e-commerce',
  },
  {
    active: false,
    icon: Wifi,
    name: 'Provedor de internet',
  },
  {
    active: false,
    icon: AccountBalanceOutlinedIcon,
    name: 'Bancos',
  },
  {
    active: false,
    icon: DollarSign,
    name: 'Instituições financeiras',
  },
  {
    active: false,
    icon: Truck,
    name: 'Transportadoras',
  },
  {
    active: false,
    icon: Heart,
    name: 'Operadora de saúde',
  },
  {
    active: false,
    icon: Archive,
    name: 'Sistemas do governo',
  },
  {
    active: false,
    icon: Mail,
    name: 'Serviços de e-mail',
  },
  {
    active: false,
    icon: Cloud,
    name: 'Serviços de nuvem',
  },
]

const DATA_SHARE_CUSTOM: TemplateType[] = []

const privacyChannelConfigurationSteps = {
  DATA_SHARE_CUSTOM,
  DATA_SHARE_DEFAULT,
  DATA_TREATMENTS_CUSTOM,
  DATA_TREATMENTS_DEFAULTS,
  NONE,
  STEPS,
  TEMPLATE_LOCAWEB,
  TEMPLATE_PLATFORMS,
  TEMPLATE_RD_CRM,
  TEMPLATE_RD_MARKETING,
  TEMPLATE_TRAY,
  TEMPLATE_VINDI,
}

export default privacyChannelConfigurationSteps
